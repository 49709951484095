import React from 'react';
import './Footer.css'

const Footer = () => {
    return (
        <footer>
            <div className='contact'>
                <img src='./instagram-logo.svg' alt='logo'></img>
                <a href="mailto:MORPHOZ@GMAIL.COM">MORPHOZ@GMAIL.COM</a>
            </div>
            <div className='legal'>
                <a href="./CGU - Conditions générales d'utilisation.pdf" target='_blank'>CGU</a>
            </div>
        </footer>
    );
};

export default Footer;